<template>
    <div class="content" id="benuzeroverview">
        <appSideNavigation></appSideNavigation>
        <appAddUserModal :showAddUserModal="isAddUserModalVisible"></appAddUserModal>
        <appModal></appModal>
        <div class="main-content-container-closed">
            <div class="site-header">
                <h3>{{name}}</h3>
            </div>
            <div class="site-content">
                <div class="table" id="results">
                    <div class='theader'>
                        <div class='table_header'>E-Mail</div>
                        <div class='table_header'>Name</div>
                        <div class='table_header'>Aktionen</div>
                    </div>
                    <div class='table_row' v-for="user in users" :key="user.id">
                        <div class='table_small'>
                            <div class='table_cell'>E-Mail</div>
                            <div class='table_cell'>{{ user.mail }}</div>
                        </div>
                        <div class='table_small'>
                            <div class='table_cell'>Name</div>
                            <div class='table_cell'>{{ user.fname }} {{ user.lname }}</div>
                        </div>
                        <div class='table_small'>
                            <div class='table_cell'>Aktionen</div>
                            <div class='table_cell'>
                                <router-link :to="{ name: 'Benutzer', params: { id: user.id }}">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row new-user-container">
                    <div class="twelve columns">
                        <button @click="openAddUserModal()" class="button-primary">Neuer Benutzer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideNavigation from '../components/SideNavigation.vue'
import AddUserModal from '../components/AddUserModal.vue'
import Modal from '../components/Modal.vue'
import Util from '../mixins/util'

export default{
    data () {
        return {
            name: 'Benutzerübersicht',
            users: null,
            isAddUserModalVisible: false
        }
    },
    mounted: async function () {
        this.navigateDependingOnSession()
        this.users = await window.dataController.GetUsers()
    },
    methods: {
        openAddUserModal: function () {
            this.isAddUserModalVisible = true
        },
        closeAddUserModal: function () {
            this.isAddUserModalVisible = false
        },
        addNewUser: async function (userMail) {
            let response = await window.dataController.InviteUser({mail: userMail})
            if (response) {
                window.dataController.ShowMessage('Der Person wurde erfolgreich eine Einladung verschickt.', false, 'success')
            }
        }
    },
    components: {
        appSideNavigation: SideNavigation,
        appAddUserModal: AddUserModal,
        appModal: Modal
    },
    mixins: [Util]
};
</script>

<style scoped>
.Benutzer a {
    color:black;
    text-decoration: none;
}
.Benutzer > div {
    border-bottom: 1px solid white;
    transition: all 0.6s ease;
    padding: 10px 0px;
}
.Benutzer > div:hover {
    border-bottom: 1px solid #F9A36B;
    cursor: pointer;
}

.Benutzer {
    padding: 20px;
    background-color: rgb(239 236 236 / 80%);
    border-radius: 20px;
}
.table {
    display: table;
    text-align: center;
    width: 100%;
    margin: 40px 0;
    border-collapse: separate;
    font-weight: 400;
}
.table_row {
    display: table-row;
    background: white;
    box-shadow: 2px 3px 10px 2px grey; 
}
.theader {
    display: table-row;
}
.table_header {
    display: table-cell;
    border-bottom: #476DB4 1px solid;
    border-top: #476DB4 1px solid;
    background: #476DB4;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    border-right: 1px solid black;
    box-shadow: 2px 3px 10px 2px grey;
}
.table_header:first-child {
    border-left: #476DB4 1px solid;
    border-top-left-radius: 5px;
}
.table_header:last-child {
    border-right: #476DB4 1px solid;
    border-top-right-radius: 5px;
}
.table_small {
    display: table-cell;
    border-right: 1px solid;
}
.table_row > .table_small > .table_cell:nth-child(odd) {
    display: none;
    background: #476DB4;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.table_row > .table_small > .table_cell {
    padding: 10px 0;
}
.table_row > .table_small:first-child > .table_cell {
    border-left: #ffffff 1px solid;
}
.table_row > .table_small:last-child > .table_cell {
    border-right: #ffffff 1px solid;
}
.table_cell i {
    color: #222;
}
.table_row:last-child > .table_small:last-child > .table_cell:last-child {
    border-bottom-right-radius: 5px;
}
.table_row:last-child > .table_small:first-child > .table_cell:last-child {
    border-bottom-left-radius: 5px;
}
.table_row:nth-child(2n+3) {
    /* background: #e9e9e9; */
    background-color: rgb(239 236 236 / 80%);;
}
.new-user-container {
    text-align: right;
}
@media (min-width: 651px) {
    .table_row:hover {
        background-color: #F9A36B;
    }
}
@media screen and (max-width: 650px) {
    .table {
        display: block;
    }
    .table_row:nth-child(2n+3) {
        /* background: none; */
        background: rgba(255,255,255,0.8);
    }
    .theader {
        display: none;
    }
    .table_row > .table_small > .table_cell:nth-child(odd) {
        display: table-cell;
        width: 50%;
    }
    .table_cell {
        display: table-cell;
        width: 50%;
    }
    .table_row {
        display: table;
        width: 100%;
        border-collapse: separate;
        /* padding-bottom: 20px; */
        margin: 5% auto 0;
        text-align: center;
    }
    .table_small {
        display: table-row;
    }
    .table_row > .table_small:first-child > .table_cell:last-child {
        border-left: none;
    }
    .table_row > .table_small > .table_cell:first-child {
        border-left: #ccc 1px solid;
    }
    .table_row > .table_small:first-child > .table_cell:first-child {
        /* border-top-left-radius: 5px; */
        border-top: #ccc 1px solid;
    }
    .table_row > .table_small:first-child > .table_cell:last-child {
        /* border-top-right-radius: 5px; */
        border-top: #ccc 1px solid;
    }
    .table_row > .table_small:last-child > .table_cell:first-child {
        border-right: none;
    }
    .table_row > .table_small > .table_cell:last-child {
        border-right: #ccc 1px solid;
    }
    /* .table_row > .table_small:last-child > .table_cell:first-child {
        border-bottom-left-radius: 5px;
    }
    .table_row > .table_small:last-child > .table_cell:last-child {
        border-bottom-right-radius: 5px;
    } */
}

@media (max-width: 550px) {
  div.main-content-container-closed {
    padding: 12px;
  }
}
</style>
