<template>
    <div v-if="showAddUserModal" class="app-user-modal">
        <div class="modal-background">
            <div id="app-modal-container" class="modal-container">
                <div id="app-modal-header" class="modal-header">
                    <font-awesome-icon id="app-modal-close" class="svg" icon="times" size="2x" @click="closeModal" />
                </div>
                <div id="app-modal-body" class="modal-body">
                    <div class="row">
                        <div class="twelve columns">
                            <p id="app-add-user-modal-message" class="modal-message"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="six columns">
                            <div class="input-div one">
                                <div>
                                    <h5>E-Mail</h5>
                                    <input class="input" v-model="userMail" type="text" id="user" @focus="focusFunction" @blur="blurFunction">
                                </div>
                            </div>
                        </div>
                        <div class="six columns button-container">
                            <button @click="addUser()" class="button-primary">Hinzufügen</button>
                        </div>
                    </div>
                </div>
                <div id="app-modal-footer" class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Util from '../mixins/util'

export default {
name: 'Benutzer hinzufügen',
    data () {
        return {
            userMail: ''
        }
    },
    props: ['showAddUserModal'],
    created () {
        window.addEventListener('scroll', this.handleModalScroll('app-user-modal'))
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleModalScroll('app-user-modal'))
    },
    mounted: function () {

    },
    methods: {
        closeModal: function () {
            this.userMail = ''
            this.$parent.closeAddUserModal()
        },
        addUser: function () {
            // evtl. noch konkrete E-Mail Validierung
            if (this.userMail !== '') {
                this.$parent.addNewUser(this.userMail)
            } else {
                this.showAddUserMessage('Bitte geben Sie eine E-Mail ein')
            }
        },
        showAddUserMessage: function (text) {
            let message = document.querySelector('#app-add-user-modal-message')
            if (message) {
                message.innerHTML = text
            }
        }
    },
    mixins: [Util]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-user-modal {
    z-index: 1;
}
.modal-container {
    z-index: 10;
    border: 2px solid black;
    background-color: #ffffff;
    height: 20vh;
    width: 75%;
    align-self: center;
    padding: 1vh 2vh;
    border-radius: 20px;
}
.modal-background {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(50,50,50,0.5);
    display: flex;
    justify-content: center;
}
.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 4vh;
}
.modal-header svg {
    align-self: center;
    color: #263c8e;
    cursor: pointer;
}
.modal-body {
    overflow: auto;
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.modal-message {
    font-size: 20px;
    align-self: center;
    margin: 0;
    color: red;
}
.modal-footer {
    height: 1vh;
}
div.input-div {
    position: relative;
    margin: 15px 0;
    padding: 5px 0;
    border-bottom: 2px solid #999;
}
.input-div:after, .input-div:before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #092A67;
    transition: .3s;
}
.input-div:after {
    right: 50%;
}
.input-div:before {
    left: 50%;
}
div.input-div.focus .icon i {
    color: #092A67;
}
.input-div.focus div h5 {
    top: -5px;
    font-size: 15px;
}
.input-div.focus:after, .input-div.focus:before {
    width: 50%;
}
div.icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
div.icon i {
    color: #999;
    transition: .3s;
}
div.input-div > div {
    position: relative;
    height: 45px;
}
div.input-div > div h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
}
.input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: bold;
    color: #555;
}
.input:focus {
    border: none;
}
.button-container {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.button-container button {
    width: 80%;
}
@media screen and (max-width: 900px) {
    .modal-container {
        width: 60%;
    }
}
@media (max-width: 550px) {
    .button-container button {
        width: 100%;
    }
    .modal-container {
        width: 80%;
        height: 30vh;
    }
    .modal-body {
        justify-content: flex-start;
        height: 25vh;
    }
}
</style>
